<template>
  <div>
    <maca-modal
      titulo="Modificar Departamento"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-form
        :model="form"
        ref="form"
        :rules="formRules"
        v-loading="cargando"
        label-position="left"
        label-width="130px"
      >
        <el-form-item label="Nombre" prop="nombre">
          <el-input v-model="form.nombre" maxlength="20"></el-input>
        </el-form-item>

        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "modificar-usuario",
  data() {
    return {
      urlProvincia: "/provincia/obtenerTodosSelect",
      form: {
        nombre: null,
        provincia: null,
      },
      impedirClose: false,
      cargando: false,
      id: 0,
      idPadre: 0,
      persona: null,

      formRules: {
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    abrir(id, idPadre) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.id = id;
      this.idPadre = idPadre;
      this.getDatos();
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    async getDatos() {
      this.cargando = true;

      let respuestaApi = await this.$maca.api.get(
        "/departamento/obtenerDatos",
        {
          id: this.id,
        }
      );

      this.cargando = false;

      if (respuestaApi != null) {
        this.form.nombre = respuestaApi.datos.nombre;
        this.form.codigo = respuestaApi.datos.codigo;
      } else {
        this.cerrar();
      }
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        id: this.id,
        nombre: this.form.nombre,
        provinciaID: this.idPadre,

      };

      let respuestaApi = await this.$maca.api.post(
        "/departamento/actualizar",
        params
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Departamento actualizada con éxito!",
          type: "success",
        });

        this.$emit("listo");
        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
    },
  },
};
</script>
