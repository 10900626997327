var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: { titulo: "Nueva Localidad", impedirClose: _vm.impedirClose }
        },
        [
          _c(
            "el-card",
            {
              staticClass: "mostrarOcultarCard",
              staticStyle: { margin: "1.5%" },
              attrs: { "body-style": { padding: "2%" }, shadow: "never" }
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-position": "top",
                    "label-width": "130px"
                  }
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 15 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Nombre", prop: "nombre" } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "30" },
                                model: {
                                  value: _vm.form.nombre,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "nombre", $$v)
                                  },
                                  expression: "form.nombre"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 9 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Código Postal", prop: "cp" } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "10" },
                                on: {
                                  keypress: function($event) {
                                    return _vm.isNumber($event)
                                  }
                                },
                                model: {
                                  value: _vm.form.cp,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "cp", $$v)
                                  },
                                  expression: "form.cp"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("maca-boton-guardar", {
                        attrs: { onSubmit: _vm.onSubmit }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-card",
            {
              staticClass: "ocultarMostrarCard",
              staticStyle: { margin: "1.5%" },
              attrs: { "body-style": { padding: "2%" }, shadow: "never" }
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-position": "left",
                    "label-width": "130px"
                  }
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 15 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Nombre", prop: "nombre" } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "30" },
                                model: {
                                  value: _vm.form.nombre,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "nombre", $$v)
                                  },
                                  expression: "form.nombre"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 9 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Código Postal", prop: "cp" } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "10" },
                                on: {
                                  keypress: function($event) {
                                    return _vm.isNumber($event)
                                  }
                                },
                                model: {
                                  value: _vm.form.cp,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "cp", $$v)
                                  },
                                  expression: "form.cp"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("maca-boton-guardar", {
                        attrs: { onSubmit: _vm.onSubmit }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }