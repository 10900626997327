<template>
  <div>
    <maca-titulo-pagina
      icono="el-icon-folder-opened"
      nombrePagina="Categorías"
      :botonNuevoHabilitado="false"
    ></maca-titulo-pagina>

    <div>
      <el-card
        :body-style="{ padding: '20px' }"
        style="margin: 30px"
        v-loading="cargando"
      >
        <div slot="header">
          <div style="margin-left: 0px">
            <el-input
              class="buscador"
              prefix-icon="el-icon-search"
              v-model="buscar"
              placeholder="Buscar"
              style="width: 300px"
            >
            </el-input>
          </div>
          <el-button
            class="botonmasIcono"
            icon="el-icon-plus"
            type="primary"
            round
            style="margin-right: 0px"
            @click="$refs.modalNuevoPais.abrir()"
            >Nuevo país</el-button
          >
        </div>

        <!-- 
            v-bind:style= "[node.data.nivel == 0 ? {'background-color': '#ebecf1'} : {'background-color': 'red'}]" -->
        <div>
          <el-tree
            :data="paises"
            node-key="id"
            style="background-color: #ebecf1; border-radius: 10px"
          >
            <span class="custom-tree-node" slot-scope="{ node }">
              <span>{{ node.label }}</span>
              <span>
                <div v-if="node.data.nivel == 0">
                  <el-button
                    size="small"
                    @click="$refs.modalNuevoProvincia.abrir(node.data.id)"
                    plain
                    round
                  >
                    <i class="el-icon-plus"></i>
                    Nueva provincia
                  </el-button>

                  <el-button
                    type="default"
                    size="small"
                    @click="$refs.modalModificarPais.abrir(node.data.id)"
                    plain
                    round
                  >
                    <i class="el-icon-edit"></i>
                  </el-button>
                  <el-button
                    type="danger"
                    size="small"
                    @click="() => eliminar(node.data.id, 1)"
                    plain
                    round
                    style="margin-left: 15px"
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </div>
                <div v-if="node.data.nivel == 1">
                  <el-button
                    size="small"
                    @click="$refs.modalNuevoDepartamento.abrir(node.data.id)"
                    plain
                    round
                  >
                    <i class="el-icon-plus"></i>
                    Nuevo departamento
                  </el-button>

                  <el-button
                    type="default"
                    size="small"
                    @click="
                      $refs.modalModificarProvincia.abrir(
                        node.data.id,
                        node.data.padreID
                      )
                    "
                    plain
                    round
                  >
                    <i class="el-icon-edit"></i>
                  </el-button>
                  <el-button
                    type="danger"
                    size="small"
                    @click="() => eliminar(node.data.id, 2)"
                    plain
                    round
                    style="margin-left: 15px"
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </div>
                <div v-if="node.data.nivel == 2">
                  <el-button
                    size="small"
                    @click="$refs.modalNuevoLocalidad.abrir(node.data.id)"
                    plain
                    round
                  >
                    <i class="el-icon-plus"></i>
                    Nueva localidad
                  </el-button>

                  <el-button
                    type="default"
                    size="small"
                    @click="
                      $refs.modalModificarDepartamento.abrir(
                        node.data.id,
                        node.data.padreID
                      )
                    "
                    plain
                    round
                  >
                    <i class="el-icon-edit"></i>
                  </el-button>
                  <el-button
                    type="danger"
                    size="small"
                    @click="() => eliminar(node.data.id, 3)"
                    plain
                    round
                    style="margin-left: 15px"
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </div>
                <div v-if="node.data.nivel == 3">
                  <el-button
                    size="small"
                    @click="$refs.modalNuevoParaje.abrir(node.data.id)"
                    plain
                    round
                  >
                    <i class="el-icon-plus"></i>
                    Nuevo Paraje
                  </el-button>

                  <el-button
                    type="default"
                    size="small"
                    @click="
                      $refs.modalModificarLocalidad.abrir(
                        node.data.id,
                        node.data.padreID
                      )
                    "
                    plain
                    round
                  >
                    <i class="el-icon-edit"></i>
                  </el-button>
                  <el-button
                    type="danger"
                    size="small"
                    @click="() => eliminar(node.data.id, 4)"
                    plain
                    round
                    style="margin-left: 15px"
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </div>

                <div v-if="node.data.nivel == 4">
                

                  <el-button
                    type="default"
                    size="small"
                    @click="
                      $refs.modalModificarParaje.abrir(
                        node.data.id,
                        node.data.padreID
                      )
                    "
                    plain
                    round
                  >
                    <i class="el-icon-edit"></i>
                  </el-button>
                  <el-button
                    type="danger"
                    size="small"
                    @click="() => eliminar(node.data.id, 5)"
                    plain
                    round
                    style="margin-left: 15px"
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </div>
              </span>
            </span>
          </el-tree>
        </div>
      </el-card>
    </div>

    <modal-nuevo-pais
      ref="modalNuevoPais"
      @listo="getPaises"
    ></modal-nuevo-pais>
    <modal-modificar-pais
      ref="modalModificarPais"
      @listo="getPaises"
    ></modal-modificar-pais>

    <modal-nuevo-provincia
      ref="modalNuevoProvincia"
      @listo="getPaises"
    ></modal-nuevo-provincia>
    <modal-modificar-provincia
      ref="modalModificarProvincia"
      @listo="getPaises"
    ></modal-modificar-provincia>

    <modal-nuevo-departamento
      ref="modalNuevoDepartamento"
      @listo="getPaises"
    ></modal-nuevo-departamento>
    <modal-modificar-departamento
      ref="modalModificarDepartamento"
      @listo="getPaises"
    ></modal-modificar-departamento>

    <modal-nuevo-localidad
      ref="modalNuevoLocalidad"
      @listo="getPaises"
    ></modal-nuevo-localidad>
    <modal-modificar-localidad
      ref="modalModificarLocalidad"
      @listo="getPaises"
    ></modal-modificar-localidad>

     <modal-nuevo-paraje
      ref="modalNuevoParaje"
      @listo="getPaises"
    ></modal-nuevo-paraje>
    <modal-modificar-paraje
      ref="modalModificarParaje"
      @listo="getPaises"
    ></modal-modificar-paraje>
  </div>
</template>

<script>
import ModalNuevoPais from "../paises/modales/nuevo";
import ModalNuevoProvincia from "../provincias/modales/nuevo";
import ModalNuevoDepartamento from "../departamentos/modales/nuevo";
import ModalNuevoLocalidad from "../localidades/modales/nuevo";
import ModalNuevoParaje from "../parajes/modales/nuevo";

import ModalModificarPais from "../paises/modales/modificar";
import ModalModificarProvincia from "../provincias/modales/modificar";
import ModalModificarDepartamento from "../departamentos/modales/modificar";
import ModalModificarLocalidad from "../localidades/modales/modificar";
import ModalModificarParaje from "../parajes/modales/modificar";

export default {
  name: "pais",
  components: {
    ModalNuevoPais,
    ModalNuevoProvincia,
    ModalNuevoDepartamento,
    ModalNuevoLocalidad,
    ModalNuevoParaje,
    ModalModificarPais,
    ModalModificarProvincia,
    ModalModificarDepartamento,
    ModalModificarLocalidad,
    ModalModificarParaje
  },
  data() {
    return {
      urlTabla: "/pais/obtenerTodosArbol",
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,
      paises: [],
      filtroNombre: null,
      cargando: true,
      buscar: "",
    };
  },
  mounted() {
    this.getPaises();
  },
  methods: {
    editarItem(pais) {},
    async eliminar(id, tipo) {
      let tipoEliminar;
      if (tipo == 1) {
        tipoEliminar = "pais";
      } else if (tipo == 2) {
        tipoEliminar = "provincia";
      } else if (tipo == 3) {
        tipoEliminar = "departamento";
      } else if (tipo == 4) {
        tipoEliminar = "localidad";
      } else if (tipo == 5) {
        tipoEliminar = "paraje";
      }

      let confirmado = await this.$confirm(
        "Confirme si desea eliminar la " + tipoEliminar + "."
      );

      if (!confirmado) {
        return;
      }

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post(
        "/" + tipoEliminar + "/eliminar",
        params
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡" + tipoEliminar + " borrada con éxito!",
          type: "success",
        });
        this.getPaises();
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },

    async getPaises() {
      this.cargando = true;

      let respuestaApi = await this.$maca.api.get("/pais/obtenerTodosArbol");

      this.cargando = false;

      if (respuestaApi != null) {
        this.paises = respuestaApi.datos;
      } else {
        this.cerrar();
      }
    },
  },
  watch: {
    buscar() {
      this.getPaises();
    },
  },
};
</script>
<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.el-tree-node__content {
  height: 45px;
}
</style>
