<template>
  <div>
    <maca-modal titulo="Nuevo País" :impedirClose="impedirClose" ref="modal">
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="mostrarOcultarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="top"
          label-width="130px"
        >
          <el-form-item label="Nombre" prop="pais">
            <el-input maxlength="20" v-model="form.pais"></el-input>
          </el-form-item>

          <el-row :gutter="20">
            <el-col :span="9">
              <el-form-item label="Código del país" prop="codigo" width="12">
                <el-input
                  maxlength="10"
                  placeholder="Ej: Código de Argentina = AR"
                  v-model="form.codigo"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item>
            <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card
        :body-style="{ padding: '2%' }"
        style="margin: 1.5%"
        shadow="never"
        class="ocultarMostrarCard"
      >
        <el-form
          :model="form"
          ref="form"
          :rules="formRules"
          label-position="left"
          label-width="130px"
        >
          <el-form-item label="Nombre" prop="pais">
            <el-input v-model="form.pais"></el-input>
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="9">
              <el-form-item label="Código del país" prop="codigo" width="12">
                <el-input
                  maxlength="10"
                  placeholder="Ej: Código de Argentina = AR"
                  v-model="form.codigo"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
          </el-form-item>
        </el-form>
      </el-card>
    </maca-modal>
  </div>
</template>

<style scoped>
</style>

<script>
export default {
  name: "nuevo-usuario",
  data() {
    return {
      form: {
        pais: null,
        codigo: null,
      },
      impedirClose: false,
      persona: null,

      formRules: {
        pais: [
          {
            required: true,
            message: "Por favor introduzca el nombre del país.",
            trigger: "change",
          },
        ],
        codigo: [
          {
            required: false,
            message: "",
            trigger: "change",
          },
        ],
      },

      value1: true,
      value2: true,

      esMail: false,
    };
  },
  methods: {
    abrir() {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.persona = null;
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$maca.eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        nombre: this.form.pais,
        codigo: this.form.codigo,
      };

      let respuestaApi = await this.$maca.api.post("/pais/crear", params);
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡País creado con éxito!",
          type: "success",
        });
        this.$emit("listo");
        return true;
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.password = this.persona.dni;
    },
  },
};
</script>

<style scoped>
.ocultarMostrarCard {
  display: none;
}
.mostrarOcultarCard {
  display: block;
}
@media screen and (max-device-width: 645px) {
  .ocultarMostrarCard {
    display: block;
  }
  .mostrarOcultarCard {
    display: none;
  }
}
</style>